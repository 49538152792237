import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ConversionLink from "../components/conversion-link"
import { conversionTypes } from "../integration/gtag"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const Herzliya = () => {
  const { phone } = useSiteMetadata()

  return (
    <Layout>
      <SEO title="מנעולן בהרצליה" />
      <div className="row">
        <div className="col-xs-12 col-lg-9">
          <h2>מנעולן בהרצליה</h2>

          <p>
            דמיינו את זה: אתם חוזרים הביתה אחרי יום ארוך ומגלים שהדלת ננעלה,
            ואתם לא מצליחים לפתוח אותה. זה עלול להיות מתסכל, אבל אל דאגה! מנעולן
            מקצועי ויעיל מגיע במהרה, פותח את הדלת ומחליף את המנעול תוך דקות
            ספורות. הכל חוזר לשגרה, ואתם יכולים להרגיש בטוחים בביתכם שוב.
          </p>

          <h3>שירותי מנעולנות ופריצת רכבים</h3>

          <ul>
            <li>
              <strong>פריצת מנעולים ודלתות בהרצליה</strong> – ללא נזק ובמהירות.
            </li>
            <li>
              <strong>החלפת מנעולים וצילינדרים</strong> – התאמה מדויקת למגוון
              סוגי הדלתות.
            </li>
            <li>
              <strong>התקנת מנעולים חדשים</strong> – שדרוג הבטיחות בבית או בעסק.
            </li>
            <li>
              <strong>שירותי פריצה לרכבים בהרצליה</strong> – פתרונות מהירים
              למגוון סוגי רכבים.
            </li>
          </ul>
          <br />
          <p>
            בין אם מדובר בפריצת מנעולים, החלפת צילינדרים או התקנת מנעולים חדשים,
            המטרה שלנו היא לספק פתרון מהיר, בטוח ומותאם אישית לכל לקוח. עם
            ניסיון עשיר וצוות מורשה, אנו מבטיחים שתמיד תיהנו משירות ברמה הגבוהה
            ביותר.
          </p>
          <br />
          <p>
            אם גם אתם רוצים שירות מהיר, מקצועי ואמין, התקשרו ל-
            <strong>
              {" "}
              <ConversionLink
                href={`tel:${phone}`}
                conversionId={conversionTypes.herzliya}
              >
                <i className="fa fa-phone" /> {phone}
              </ConversionLink>
            </strong>{" "}
            להזמנת מנעולן מקצועי בהרצליה!
          </p>
        </div>
        <div className="d-none d-lg-block col-lg-3 sidebar">
          <h4>שעות פעילות:</h4>
          <p className="row d-flex">
            <div className="d-inline-block">
              <div>{"יום א' - ה':"}</div>
              <div> שישי - שבת:</div>
            </div>
            <div className="d-inline-block">
              <div>7:00 - 21:00</div>
              <div>7:00 - 21:00</div>
            </div>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Herzliya
