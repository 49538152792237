import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import locations from "../components/locations.json"
import "../components/style.css"
import { Link } from "gatsby"

const LocationsPage = () => (
  <Layout>
    <SEO title="איזורי שרות" />
    <div className="row">
      <div className="col-xs-12 col-lg-9">
        <h2>איזורי שרות</h2>
        <ul>
          {locations.map((location) => (
            <li key={location.name}>
              {location.href ? (
                <Link to={location.href}>{location.name}</Link>
              ) : (
                location.name
              )}
            </li>
          ))}
        </ul>
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1FGXcXvSkls9x-bNOEumPcWnrQ2VX7HQJ&ehbc=2E312F&hl=he"
          width="90%"
          height="450"
          frameBorder="0"
          style={{
            border: 0,
            margin: "0 auto",
            textAlign: "center",
            display: "block",
          }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
      <div className="d-none d-lg-block col-lg-3 sidebar">
        <h4>שעות פעילות:</h4>
        <p className="row d-flex">
          <div className="d-inline-block">
            <div>{"יום א' - ה':"}</div>
            <div> שישי - שבת:</div>
          </div>
          <div className="d-inline-block">
            <div>7:00 - 21:00</div>
            <div>7:00 - 21:00</div>
          </div>
        </p>
      </div>
    </div>
  </Layout>
)

export default LocationsPage
